<template>
  <div></div>
</template>

<script>
import { HANDLE_URL } from '@/store/actions.type'
import {mapGetters} from "vuex";
export default {
  async mounted() {
    if (!this.isAuthenticated) {
      await this.$router.push({ name: 'dashboard-home' })
      return
    }

    await this.$store
      .dispatch(HANDLE_URL, this.$route.params.projectUrl)
      .then((resp) => {
        if (resp) {
          this.$router.push({ name: 'composer', params: { projectId: resp } })
        } else {
          this.$router.push({ name: 'dashboard-home' })
        }
      })
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>
